import { FC } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { FaFacebook, FaYelp } from 'react-icons/fa';
import { usePlausible } from 'next-plausible';
import logo from '../../../public/images/logos/hlb-electric-logo-small.svg';

const footerNavigation = {
  projects: [
    { name: 'Lighting', href: '/projects/lighting' },
    { name: 'Ceiling Fans', href: '/projects/ceiling-fans' },
    { name: 'Security Cameras', href: '/projects/security-cameras' },
    {
      name: 'Outlets & Light Switches',
      href: '/projects/outlets-and-light-switches',
    },
  ],
  company: [
    { name: 'Services', href: '/electrical-services' },
    { name: 'About', href: '/about' },
    { name: 'Contact Us', href: '/contact-us' },
  ],
  social: [
    {
      name: 'Facebook',
      href: 'https://www.facebook.com/HLBElectric/',
      icon: FaFacebook,
    },
    {
      name: 'Yelp',
      href: 'https://www.yelp.com/biz/hlb-electric-maricopa',
      icon: FaYelp,
    },
  ],
};

const Footer: FC = () => {
  const plausible = usePlausible();
  return (
    <footer className="bg-gray-50" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="mx-auto max-w-7xl py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="space-y-8 xl:col-span-1">
            <div className="h-10 w-11">
              <Link href="/" prefetch={false}>
                <span className="sr-only">HLB Electric</span>
                <Image src={logo} alt="HLB Electric" />
              </Link>
            </div>
            <p className="text-base text-gray-500">
              Let us help enlighten your project
            </p>
            <div className="flex space-x-6">
              {footerNavigation.social.map((item) => (
                <a
                  onClick={() =>
                    plausible('footerNavigation', {
                      props: {
                        name: item.name,
                      },
                    })
                  }
                  key={item.name}
                  href={item.href}
                  className="text-gray-400 hover:text-gray-500"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="sr-only">{item.name}</span>
                  <item.icon className="h-6 w-6" aria-hidden="true" />
                </a>
              ))}
            </div>
          </div>
          <div className="mt-12 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold uppercase tracking-wider text-gray-900">
                  Projects
                </h3>
                <ul role="list" className="mt-4 space-y-4">
                  {footerNavigation.projects.map((item) => (
                    <li key={item.name}>
                      <Link
                        href={item.href}
                        prefetch={false}
                        onClick={() =>
                          plausible('footerNavigation', {
                            props: {
                              name: item.name,
                            },
                          })
                        }
                        className="text-base text-gray-500 hover:text-gray-900"
                      >
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold uppercase tracking-wider text-gray-900">
                  Company
                </h3>
                <ul role="list" className="mt-4 space-y-4">
                  {footerNavigation.company.map((item) => (
                    <li key={item.name}>
                      <Link
                        href={item.href}
                        prefetch={false}
                        onClick={() =>
                          plausible('footerNavigation', {
                            props: {
                              name: item.name,
                            },
                          })
                        }
                        className="text-base text-gray-500 hover:text-gray-900"
                      >
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-12 border-t border-gray-200 pt-8">
          <p className="text-base text-gray-500 xl:text-center">
            &copy; {new Date().getFullYear()} HLB Electric, LLC. All rights
            reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
