import React, { FC } from 'react';
import dynamic from 'next/dynamic';
import cn from 'classnames';
import { LocalBusinessJsonLd } from 'next-seo';
import Header from '@components/common/Header';

const Footer = dynamic(() => import('@components/common/Footer'));

type LayoutProps = {
  children: React.ReactNode;
};

const Layout: FC<LayoutProps> = ({ children }) => {
  return (
    <>
      <LocalBusinessJsonLd
        type="Electrician"
        id="https://www.hlbelectric.com"
        name="HLB Electric"
        description="HLB Electric services Maricopa, AZ and the surrounding area."
        telephone="+15206374702"
        url="https://www.hlbelectric.com"
        sameAs={['https://www.facebook.com/HLBElectric/']}
        address={{
          streetAddress: '',
          addressLocality: 'Maricopa',
          addressRegion: 'AZ',
          postalCode: '85138',
          addressCountry: 'US',
        }}
        openingHours={[
          {
            opens: '07:00',
            closes: '17:00',
            dayOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
          },
          {
            opens: '07:00',
            closes: '12:00',
            dayOfWeek: ['Saturday', 'Sunday'],
          },
        ]}
        areaServed={[
          {
            geoMidpoint: {
              latitude: '33.058106',
              longitude: '-112.047642',
            },
            geoRadius: '34600',
          },
          {
            geoMidpoint: {
              latitude: '32.881893',
              longitude: '-111.768036',
            },
            geoRadius: '88996',
          },
          {
            geoMidpoint: {
              latitude: '33.342667',
              longitude: '-111.983997',
            },
            geoRadius: '28807',
          },
          {
            geoMidpoint: {
              latitude: '33.307575',
              longitude: '-111.844940',
            },
            geoRadius: '52577',
          },
        ]}
        rating={{
          ratingValue: '5',
          ratingCount: '2',
        }}
        action={{
          actionName: 'potentialAction',
          actionType: 'ReviewAction',
          target: 'https://www.yelp.com/biz/hlb-electric-maricopa',
        }}
      />
      <div className="relative mx-auto h-full overflow-hidden bg-white transition-colors duration-150">
        <Header />
        <main className="fit">{children}</main>
        <Footer />
      </div>
    </>
  );
};

export default Layout;
