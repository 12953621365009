import '@styles/main.css';
import '@styles/chrome-bug.css';
import 'keen-slider/keen-slider.min.css';

import { FC, useEffect } from 'react';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import PlausibleProvider from 'next-plausible';
import { Head } from '@components/common';

type NoopProps = {
  children: React.ReactNode;
};

const Noop: FC<NoopProps> = ({ children }) => <>{children}</>;

export default function MyApp({ Component, pageProps }: AppProps) {
  const Layout = (Component as any).Layout || Noop;
  const router = useRouter();

  useEffect(() => {
    document.body.classList?.remove('loading');
  }, []);

  return (
    <PlausibleProvider domain="hlbelectric.com" trackOutboundLinks={true}>
      <Head />
      <Layout pageProps={pageProps}>
        <Component {...pageProps} />
      </Layout>
    </PlausibleProvider>
  );
}
