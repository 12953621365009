import { FC, Fragment } from 'react';
import cn from 'classnames';
import Link from 'next/link';
import Image from 'next/image';
import { Popover, Transition } from '@headlessui/react';
import { FaLightbulb, FaPlug, FaFan, FaCamera } from 'react-icons/fa';
import {
  Bars3Icon,
  XMarkIcon,
  CheckCircleIcon,
  PhoneIcon,
} from '@heroicons/react/24/solid';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import { usePlausible } from 'next-plausible';

const projects = [
  {
    name: 'Lighting',
    description:
      'Get a better understanding of where your traffic is coming from.',
    href: '/projects/lighting',
    icon: FaLightbulb,
  },
  {
    name: 'Ceiling Fans',
    description: 'Speak directly to your customers in a more meaningful way.',
    href: '/projects/ceiling-fans',
    icon: FaFan,
  },
  {
    name: 'Security Cameras',
    description: "Your customers' data will be safe and secure.",
    href: '/projects/security-cameras',
    icon: FaCamera,
  },
  {
    name: 'Outlets and Light Switches',
    description: "Connect with third-party tools that you're already using.",
    href: '/projects/outlets-and-light-switches',
    icon: FaPlug,
  },
];
const callsToAction = [
  { name: 'View All Projects', href: '/projects', icon: CheckCircleIcon },
  { name: 'Contact Us', href: '/contact-us', icon: PhoneIcon },
];

const Header: FC = () => {
  const plausible = usePlausible();
  return (
    <header>
      <Popover className="relative bg-white">
        <div
          className="pointer-events-none absolute inset-0 z-30 shadow"
          aria-hidden="true"
        />
        <div className="relative z-20">
          <div className="mx-auto flex max-w-7xl items-center justify-between px-4 py-5 sm:px-6 sm:py-4 md:justify-start md:space-x-10 lg:px-8">
            <div>
              <Link href="/" prefetch={false} className="flex">
                <span className="sr-only">HLB Electric</span>
                <Image
                  src="/images/logos/hlb-electric-logo-small.svg"
                  alt="HLB Electric"
                  className="relative h-10 w-11 object-contain"
                  width={44}
                  height={40}
                />
              </Link>
            </div>
            <div className="-my-2 -mr-2 md:hidden">
              <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-red-600">
                <span className="sr-only">Open menu</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </Popover.Button>
            </div>
            <div className="hidden md:flex md:flex-1 md:items-center md:justify-between">
              <Popover.Group as="nav" className="flex space-x-10">
                <Popover>
                  {({ open }) => (
                    <>
                      <Popover.Button
                        className={cn(
                          open ? 'text-gray-900' : 'text-gray-500',
                          'group inline-flex items-center rounded-md bg-white text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2'
                        )}
                      >
                        <span>Projects</span>
                        <ChevronDownIcon
                          className={cn(
                            open ? 'text-gray-600' : 'text-gray-400',
                            'ml-2 h-5 w-5 group-hover:text-gray-500'
                          )}
                          aria-hidden="true"
                        />
                      </Popover.Button>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 -translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 -translate-y-1"
                      >
                        <Popover.Panel className="absolute inset-x-0 top-full z-10 hidden transform bg-white shadow-lg md:block">
                          {({ close }) => (
                            <>
                              <div className="mx-auto grid max-w-7xl gap-y-6 px-4 py-6 sm:grid-cols-2 sm:gap-8 sm:px-6 sm:py-8 lg:grid-cols-4 lg:px-8 lg:py-12 xl:py-16">
                                {projects.map((item) => (
                                  <Link
                                    key={item.name}
                                    href={item.href}
                                    prefetch={false}
                                    onClick={() => {
                                      plausible('headerNavigation', {
                                        props: {
                                          name: item.name,
                                        },
                                      });
                                      close();
                                    }}
                                    className="-m-3 flex flex-col justify-between rounded-lg p-3 hover:bg-gray-50"
                                  >
                                    <div className="flex md:h-full lg:flex-col">
                                      <div className="shrink-0">
                                        <span className="inline-flex h-10 w-10 items-center justify-center rounded-md bg-red-600 text-white sm:h-12 sm:w-12">
                                          <item.icon
                                            className="h-6 w-6"
                                            aria-hidden="true"
                                          />
                                        </span>
                                      </div>
                                      <div className="ml-4 md:flex md:flex-1 md:flex-col md:justify-between lg:ml-0 lg:mt-4">
                                        <div>
                                          <p className="text-base font-medium text-gray-900">
                                            {item.name}
                                          </p>
                                          <p className="mt-1 text-sm text-gray-500">
                                            {item.description}
                                          </p>
                                        </div>
                                        <p className="mt-2 text-sm font-medium text-indigo-600 lg:mt-4">
                                          Learn more
                                          <span aria-hidden="true">&rarr;</span>
                                        </p>
                                      </div>
                                    </div>
                                  </Link>
                                ))}
                              </div>
                              <div className="bg-gray-50">
                                <div className="mx-auto max-w-7xl space-y-6 px-4 py-5 sm:flex sm:space-y-0 sm:space-x-10 sm:px-6 lg:px-8">
                                  {callsToAction.map((item) => (
                                    <div key={item.name} className="flow-root">
                                      <Link
                                        href={item.href}
                                        prefetch={false}
                                        onClick={() => {
                                          plausible('headerNavigation', {
                                            props: {
                                              name: item.name,
                                            },
                                          });
                                          close();
                                        }}
                                        className="-m-3 flex items-center rounded-md p-3 text-base font-medium text-gray-900 hover:bg-gray-100"
                                      >
                                        <item.icon
                                          className="h-6 w-6 shrink-0 text-gray-400"
                                          aria-hidden="true"
                                        />
                                        <span className="ml-3">
                                          {item.name}
                                        </span>
                                      </Link>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </>
                          )}
                        </Popover.Panel>
                      </Transition>
                    </>
                  )}
                </Popover>
                <Link
                  href="/electrical-services"
                  prefetch={false}
                  onClick={() =>
                    plausible('headerNavigation', {
                      props: {
                        name: 'services',
                      },
                    })
                  }
                  className="text-base font-medium text-gray-500 hover:text-gray-900"
                >
                  Services
                </Link>
                <Link
                  href="/about"
                  prefetch={false}
                  onClick={() =>
                    plausible('headerNavigation', {
                      props: {
                        name: 'about',
                      },
                    })
                  }
                  className="text-base font-medium text-gray-500 hover:text-gray-900"
                >
                  About
                </Link>
              </Popover.Group>
              <div className="flex items-center md:ml-12">
                <Link
                  href="/contact-us"
                  prefetch={false}
                  onClick={() =>
                    plausible('headerNavigation', {
                      props: {
                        name: 'contact us',
                      },
                    })
                  }
                  className="ml-8 inline-flex items-center justify-center rounded-md border border-transparent bg-gradient-to-r from-rose-600 to-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:from-rose-700 hover:to-red-700"
                >
                  Contact Us
                </Link>
              </div>
            </div>
          </div>
        </div>

        <Transition
          as={Fragment}
          enter="duration-200 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            focus
            className="absolute inset-x-0 top-0 z-30 origin-top-right transform p-2 transition md:hidden"
          >
            {({ close }) => (
              <div className="divide-y-2 divide-gray-50 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                <div className="px-5 pt-5 pb-6 sm:pb-8">
                  <div className="flex items-center justify-between">
                    <div>
                      <Image
                        src="/images/logos/hlb-electric-logo-small.svg"
                        alt="HLB Electric"
                        className="relative h-10 w-11 object-cover"
                        width={44}
                        height={40}
                      />
                    </div>
                    <div className="-mr-2">
                      <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-red-600">
                        <span className="sr-only">Close menu</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </Popover.Button>
                    </div>
                  </div>
                  <div className="mt-6 sm:mt-8">
                    <nav>
                      <div className="grid gap-7 sm:grid-cols-2 sm:gap-y-8 sm:gap-x-4">
                        {projects.map((item) => (
                          <Link
                            key={item.name}
                            href={item.href}
                            onClick={() => {
                              plausible('headerNavigation', {
                                props: {
                                  name: item.name,
                                },
                              });
                              close();
                            }}
                            className="-m-3 flex items-center rounded-lg p-3 hover:bg-gray-50"
                          >
                            <div className="flex h-10 w-10 shrink-0 items-center justify-center rounded-md bg-red-600 text-white sm:h-12 sm:w-12">
                              <item.icon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </div>
                            <div className="ml-4 text-base font-medium text-gray-900">
                              {item.name}
                            </div>
                          </Link>
                        ))}
                      </div>
                      <div className="mt-8 text-base">
                        <Link
                          href="/projects"
                          prefetch={false}
                          onClick={() => {
                            plausible('headerNavigation', {
                              props: {
                                name: 'view all projects',
                              },
                            });
                            close();
                          }}
                          className="font-medium text-red-800 hover:text-red-600"
                        >
                          View all projects
                          <span aria-hidden="true">&rarr;</span>
                        </Link>
                      </div>
                    </nav>
                  </div>
                </div>
                <div className="py-6 px-5">
                  <div className="grid grid-cols-2 gap-4">
                    <Link
                      href="/electrical-services"
                      prefetch={false}
                      onClick={() => {
                        plausible('headerNavigation', {
                          props: {
                            name: 'services',
                          },
                        });
                        close();
                      }}
                      className="rounded-md text-base font-medium text-gray-900 hover:text-gray-700"
                    >
                      Services
                    </Link>

                    <Link
                      href="/about"
                      prefetch={false}
                      onClick={() => {
                        plausible('headerNavigation', {
                          props: {
                            name: 'about',
                          },
                        });
                        close();
                      }}
                      className="rounded-md text-base font-medium text-gray-900 hover:text-gray-700"
                    >
                      About
                    </Link>
                  </div>
                  <div className="mt-6">
                    <Link
                      href="/contact-us"
                      prefetch={false}
                      onClick={() => {
                        plausible('headerNavigation', {
                          props: {
                            name: 'contact us',
                          },
                        });
                        close();
                      }}
                      className="flex w-full items-center justify-center rounded-md border border-transparent bg-gradient-to-r from-rose-600 to-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:from-rose-700 hover:to-red-700"
                    >
                      Contact Us
                    </Link>
                  </div>
                </div>
              </div>
            )}
          </Popover.Panel>
        </Transition>
      </Popover>
    </header>
  );
};

export default Header;
